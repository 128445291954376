import { host } from '@/config/env'
import { SCENE_STATUS } from '@/const/scene'
import { success } from '../plugins/notifier'
import i18n from '../plugins/i18n'
import crypto from 'crypto'

/**
 * 判断是否支持webp格式的图片
 */
const isSupportWebp =
    !![].map && document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0

/**
 * 判断是否是windows平台
 */
const isWin = ['Win32', 'Windows'].includes(navigator.platform)

/**
 * 判断是否是mac平台
 */
const isMac = ['Mac68K', 'MacPPC', 'Macintosh', 'MacIntel'].includes(navigator.platform)

/**
 * 判断是否是Chrome
 */
const isChrome = /Chrome/.test(navigator.userAgent)

/**
 * 判断是否是Firefox
 */
const isFirefox = /Firefox/.test(navigator.userAgent)

/**
 * 判断是否是Safari
 */
const isSafari = /Safari/.test(navigator.userAgent)

/**
 * 判断是否是Edge
 */
const isEdge = /Edge/.test(navigator.userAgent)

/**
 * 判断参数是哪种类型
 * @param {*} obj
 */
function checkType(obj, type) {
    return Object.prototype.toString.call(obj).toLowerCase() === `[object ${type}]`
}

/**
 * 判断参数是不是对象
 * @param {*} obj
 */
function isObject(obj) {
    return checkType(obj, 'object')
}

/**
 * 判断参数是不是数组
 * @param {*} obj
 */
function isArray(obj) {
    return checkType(obj, 'array')
}

/**
 * 判断参数是不是字符串
 * @param {*} obj
 */
function isString(obj) {
    return checkType(obj, 'string')
}

/**
 * 判断参数是不是方法
 * @param {*} obj
 */
function isFunction(obj) {
    return checkType(obj, 'function')
}

/**
 * 获取随机数
 * @param min
 * @param max
 * @returns {number}
 */
function getRandom(min, max) {
    const choose = max - min + 1
    return Math.floor(Math.random() * choose + min)
}

/**
 * 获取Chrome的版本号
 */
function getChromeVersion() {
    return Number(/Chrome\/(\d+)/.exec(navigator.userAgent)[1])
}

/**
 * 移动端检测
 */
function isMobile() {
    return (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobi/i.test(navigator.userAgent) ||
        screen.width < 500
    )
}

/**
 * 微信环境
 */
function isWeixin() {
    return /MicroMessenger/i.test(navigator.userAgent)
}

/**
 * IOS环境
 */
function isIOS() {
    return /ipad|iphone/i.test(navigator.userAgent)
}

export function formatDate(date, fmt = 'YYYY-mm-dd') {
    if (typeof date === 'number' || typeof date === 'string') {
        date = new Date(date)
    }
    let ret
    const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }
    for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
            fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
        }
    }
    return fmt
}

function formatArr(arr) {
    let str = ''
    for (let i = 0; i < arr.length; i++) {
        if (i < arr.length - 1) {
            str += arr[i] + ','
        } else {
            str += arr[i]
        }
    }
    return str
}

function formatNum(num) {
    if (!num || num < 10000) {
        return num
    }
    if (num >= 10000 && num < 100000000) {
        return (num / 10000).toFixed(1) + '万+'
    }
    if (num >= 100000000) {
        return (num / 10000).toFixed(1) + '亿+'
    }
}

function formatNumNoFix(num) {
    if (!num || num < 10000) {
        return num
    }
    if (num >= 10000 && num < 100000000) {
        return (num / 10000).toFixed(0) + '万'
    }
    if (num >= 100000000) {
        return (num / 10000).toFixed(0) + '亿'
    }
}

/**
 * '1 未发布, 2 已发布, 3, 已暂停, 4 已停用, 5 已结束, -2 有修改'
 * @param scene
 * @returns {number}
 */
function getSceneStatus(scene) {
    if (!scene) {
        return SCENE_STATUS.NOT_PUBLISHED
    }
    // 发布后才有修改
    if (scene.status === 2 && scene.publishTime && scene.publishTime < scene.updateTime) {
        return SCENE_STATUS.MODIFIED
    }
    return scene.status
}
function getSceneStatusName(scene) {
    // console.log('getSceneStatus(scene)=======', getSceneStatus(scene))
    switch (getSceneStatus(scene)) {
        case SCENE_STATUS.NOT_PUBLISHED:
            return i18n.t('scene.unpublished')
        case SCENE_STATUS.PUBLISHED:
            return i18n.t('scene.published')
        case SCENE_STATUS.PAUSED:
            return i18n.t('scene.hasPaused')
        case SCENE_STATUS.STOP:
            return i18n.t('scene.terminated')
        case SCENE_STATUS.END:
            return i18n.t('scene.over')
        case SCENE_STATUS.MODIFIED:
            return i18n.t('scene.modified')
        default:
            return ''
    }
}

function getHdStatus(scene) {
    if (scene.status === 4 || scene.status === 3 || scene.status === -1) {
        return scene.status
    }

    // 根据serverTime重新给activityStatus赋值，确定活动状态
    const serverTime = new Date()
    const startTime = new Date(scene.startTime)
    const endTime = new Date(scene.endTime)
    if (serverTime < startTime) {
        return 1
    }
    if (serverTime > endTime) {
        return 4
    }
    if (serverTime > startTime && serverTime < endTime) {
        return 2
    }
    return ''
}

function isUpdate(scene) {
    return getSceneStatus(scene) === -2
}

function isApproval(scene) {
    return getSceneStatus(scene) === 4
}

function canSubmitApproval(scene) {
    const sceneStatus = getSceneStatus(scene)
    return sceneStatus === 1 || sceneStatus === 5 || sceneStatus === -2
}

function canEditorScene(scene) {
    return (
        (scene.fromPage !== 'staff' && scene.status === 4) ||
        scene.status === 5 ||
        scene.status === 2 ||
        (scene.fromPage !== 'staff' && scene.status === 6)
    )
}

function canRecall(scene) {
    return scene.fromPage !== 'staff' && scene.status === 4
}

function sort(arr, attr) {
    if (arr && arr.length && arr.length > 0) {
        const compare = function (prop) {
            return function (obj1, obj2) {
                const val1 = obj1[prop]
                const val2 = obj2[prop]
                if (val1 > val2) {
                    return -1
                } else if (val1 < val2) {
                    return 1
                } else {
                    return 0
                }
            }
        }
        return arr.sort(compare(attr))
    }
    return arr
}

// 获取url链接上的参数
export function getUrlParam(name) {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
    const result = window.location.search.substr(1).match(reg)
    if (result !== null) {
        return decodeURI(result[2])
    } else {
        return null
    }
}
// 获取url链接上的参数
export function getUrlParamByPrefix(prefix) {
    const url = new URL(window.location.href)

    // 获取参数部分的对象
    const queryParams = url.search.substr(1).split('&')

    // 定义正则表达式
    const regex = new RegExp(`^${prefix}.*=.*$`)

    // 筛选符合正则表达式的参数名
    const matchedParams = queryParams.filter(param => regex.test(param))

    // 输出符合正则表达式的参数名
    return matchedParams
}

// 防抖函数
export function debounce(func, delay = 3000) {
    let timeout
    return (...args) => {
        timeout && clearTimeout(timeout)
        timeout = setTimeout(() => {
            func.apply(this, args)
        }, delay)
    }
}

// 作品预览类型映射
function previewUrl(type, sceneCode, path) {
    if (!type) {
        type = 'hd'
    }
    const pathMap = {
        hd: 'gs',
        h5: 's',
        lc: 'ls',
        form: 'fv'
    }

    let url = `${host.PRE_VIEW_LS}${pathMap[type]}/${sceneCode}`

    if (path) {
        url = `${path}/${pathMap[type]}/${sceneCode}`
    }
    return url
}

function getCreationEditorUrl(type, creationId) {
    let editorUrl = ''
    if (type === 'hd') {
        editorUrl = `${host.globalHOST}gc/${creationId}`
    } else if (type === 'design') {
        editorUrl = `${host.globalHOST}design/${creationId}`
    } else if (type === 'h5') {
        editorUrl = `${host.globalHOST}c/${creationId}`
    } else if (type === 'lc') {
        editorUrl = `${host.globalHOST}lc/${creationId}`
    } else if (type === 'form') {
        editorUrl = `${host.globalHOST}fe/${creationId}`
    } else {
        editorUrl = `${host.globalHOST}gc/${creationId}`
    }
    const token = window.localStorage.getItem('_auth_token')
    if (token) {
        editorUrl += `?Authorization=${token}`
        const secretId = getUrlParam('secretId')
        if (secretId) {
            editorUrl += `&secretId=${secretId}`
        }
    }
    return editorUrl
}

export function copyStr(str) {
    const transfer = document.createElement('input')
    document.body.appendChild(transfer)
    transfer.value = str
    transfer.focus()
    transfer.select()
    if (document.execCommand('copy')) {
        document.execCommand('copy')
    }
    transfer.blur()
    document.body.removeChild(transfer)
    success(i18n.t('common.copySuc'))
}

export function capacityTransform(value, options) {
    const outputUnit = options?.outputUnit?.toUpperCase()
    const inputUnit = options?.inputUnit?.toUpperCase()
    const showUnit = options?.showUnit || true
    const digits = options?.digits || false
    const capacityUnits = ['BYTE', 'KB', 'MB', 'GB', 'TB', 'PB']
    const oIndex = capacityUnits.indexOf(outputUnit)
    const iIndex = capacityUnits.indexOf(inputUnit)

    if (oIndex === -1 || iIndex === -1) {
        throw new Error('大小在 b ～ PB 之间')
    }

    const diff = iIndex - oIndex

    let output = value * 1024 ** diff
    output = digits && typeof digits === 'number' ? output.toFixed(digits) : output
    return showUnit ? output + options.outputUnit : output
}

export function stopAppLoading() {
    if (document.getElementById('app-loading-wrap')) {
        document.body.removeChild(document.getElementById('app-loading-wrap'))
    }
}

export const handleImgOnload = url => {
    return new Promise((resolve, reject) => {
        const image = new Image()
        image.crossOrigin = 'Anonymous'
        image.src = url

        image.onload = function (result) {
            resolve(result)
        }
        image.onerror = function (err) {
            reject(err)
        }
    })
}
/**
 * 是否为网址
 * */
/* eslint-disable */
export function isHttpUrl(str) {
    return /^(((ht)tps?):\/\/)[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&:\/~+#]*[\w\-@?^=%&\/~+#])?$/.test(str)
}
/**
 * 校验wixin小程序或者支付宝小程序链接
 * @param {*} url
 * @returns
 */
/* eslint-disable */
export const validateWixinOrAliPayUrl = url => {
    const reg = /^\/[\w|\/|\.|\-]+/g // 匹配/page.xx
    return reg.test(url) || url.startsWith('weixin:') || url.startsWith('alipays')
}

function uuid() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

function sha256(str) {
    const hash = crypto.createHash('sha256')
    hash.update(str)
    return hash.digest('hex')
}

function getPlayCreationIdByScene(h5Data = [], thirdType) {
    if (!thirdType) return
    let targetData = null
    for (let data of h5Data) {
        const elements = data.elements
        targetData = elements.find(element => element.thirdType === thirdType)
        if (targetData) break
    }
    if (!targetData) return
    return targetData.properties?.creationScene?.id
}

export function getJumpOrigin(type) {
    const originMap = {
        enterprise: 'enterpriseScene',
        mine: 'myScene'
    }
    return originMap[type] || 'myScene'
}

export default {
    isObject,
    isArray,
    isString,
    isFunction,
    isSupportWebp,
    isWin,
    isMac,
    isChrome,
    isFirefox,
    isSafari,
    isEdge,
    isMobile,
    isWeixin,
    isIOS,
    getRandom,
    getChromeVersion,
    formatDate,
    formatArr,
    formatNum,
    getSceneStatus,
    getSceneStatusName,
    getHdStatus,
    isUpdate,
    isApproval,
    canSubmitApproval,
    canEditorScene,
    canRecall,
    sort,
    formatNumNoFix,
    getUrlParam,
    debounce,
    getCreationEditorUrl,
    previewUrl,
    isHttpUrl,
    validateWixinOrAliPayUrl,
    uuid,
    sha256,
    getPlayCreationIdByScene,
    getJumpOrigin
}
